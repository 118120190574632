const { mapActions } = require('vuex');

export default {
	name: 'Review',
	props: ['data'],
	data() {
		return {
			commonAvg: '',
			commonMinuses: '',
			commonPluses: '',
			commonCount: 0,
			isHaveCommonInfo: false,
			reviewText: '',
			reviewId: 0,
			ratingTop: 0,
			isHaveReviews: false,
		};
	},
	filters: {
		sliceText(text = '') {
			const textLength = 150;
			return text.length > textLength ? text.slice(0, textLength).replace(/\s$/, '') + '...' : text;
		},
	},
	methods: {
		...mapActions({
			fetchCommonReviewsInfo_: 'news/commonReviewsInfo',
			fetchReviewsList_: 'news/fetchReviewsList',
		}),
		fetchCommonReviewsInfo() {
			const params = {
				markaId: this.data.markaId,
				modelId: this.data.modelId,
			};
			this.fetchCommonReviewsInfo_(params).then((response = {}) => {
				if (response && Object.keys(response).length) {
					this.isHaveCommonInfo = true;
					const { data: {
						average: { avg = 0 } = {},
						plusesStr = '',
						minusesStr = '',
						ratingTop = 0,
						count: { value = 0 } = {}
					} = {} } = response;
					this.commonPluses = plusesStr;
					this.commonMinuses = minusesStr;
					this.commonCount = value;
					this.ratingTop = ratingTop;
					this.commonAvg = `${Number(avg).toFixed(1)} ${this.getRatingStatus(avg)}`;
				}
			}).catch((e) => {
				console.error('ERROR:::fetchCommonReviewsInfo_', e.message);
				this.isHaveCommonInfo = false;
			});
		},
		fetchReviewsList() {
			const params = {
				markaId: this.data.markaId,
				modelId: this.data.modelId,
				size: 1
			};
			this.fetchReviewsList_(params).then(({data = []}) => {
				if (data.length) {
					this.isHaveReviews = true;
					const [firstReview] = data;
					const { fields: { review = '', reviewId = 0 } = {} } = firstReview;
					this.reviewText = review;
					this.reviewId = reviewId;
				}
			}).catch(e => {
				console.error('ERROR:::fetchReviewsList_', e.message);
				this.isHaveReviews = false;
			});
		},
		getRatingStatus(avg) {
			switch (true) {
				case avg < 3:
					return this.$t('погано');
				case avg <= 4.0:
					return this.$t('добре');
				default:
					return this.$t('відмінно');
			}
		},
		logClick(action) {
			this.$emit('sendChildLogsEvent', {click_action: action, action_block: 4, item_type: this.data.isMain ? 1 : 2});
		}
	},
	mounted() {
		this.fetchCommonReviewsInfo();
		this.fetchReviewsList();
	},
	i18n: require('./i18n').default,
};
