export default {
	messages: {
		ru: {
			'Відгуки про': 'Отзывы об',
		},
		uk: {
			'Відгуки про': 'Відгуки про',
		},
	}
};
