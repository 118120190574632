export default {
	messages: {
		ru: {
			'Загальна інформація': 'Общая информация',
			'Середня ціна по ринку': 'Средняя цена по рынку',
			'Змішана витрата': 'Смешанный расход',
			'Потужність двигуна': 'Мощность двигателя',
			'Час розгону': 'Время разгона',
			'від': 'от',
			'Паливо': 'Топливо',
			'Об’єм двигуна': 'Объем двигателя',
			'Тип приводу': 'Тип привода',
			'Динаміка цін': 'Динамика цен',
			'Дешевше': 'Дешевле',
			'Економніше': 'Экономнее',
			'Швидше': 'Быстрее',
		},
		uk: {
			'Загальна інформація': 'Загальна інформація',
			'Середня ціна по ринку': 'Середня ціна по ринку',
			'Змішана витрата': 'Змішана витрата',
			'Потужність двигуна': 'Потужність двигуна',
			'Час розгону': 'Час розгону',
			'від': 'від',
			'Паливо': 'Паливо',
			'Об’єм двигуна': 'Об’єм двигуна',
			'Тип приводу': 'Тип приводу',
			'Динаміка цін': 'Динаміка цін',
			'Дешевше': 'Дешевше',
			'Економніше': 'Економніше',
			'Швидше': 'Швидше'
		},
	}
};
