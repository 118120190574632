const { mapGetters } = require('vuex');

export default {
	name: 'CarsReviews',
	components: {
		'car-review': require('./components/Review/index.vue').default,
	},
	props: ['link'],
	computed: {
		...mapGetters({
			couple_: 'compareCars/couple',
		}),
		couple() {
			let result = this.couple_(this.link) || {};
			if (result.mainCar) {
				result.mainCar.isMain = 1;
			}
			return result;
		},
		cars() {
			let carList = [];
			Object.entries(this.couple).map((car = []) => {
				const [name = {}, info = {}] = car;
				carList.push({
					name,
					markaId: info.markaId,
					modelId: info.modelId,
					markaEng: info.markaEng,
					modelEng: info.modelEng,
				});
			});
			carList.sort((a, b) => b.name.localeCompare(a.name, 'en'));
			return carList;
		},
	},
	methods: {
		passLogsEvent(payload) {
			this.$emit('sendLogsEvent', payload);
		}
	},
	i18n: require('./i18n').default,
};
