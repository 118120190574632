const { mapGetters } = require('vuex');

export default {
	name: 'FloatLine',
	props: ['mainCar', 'comparedCar', 'comparedCarName', 'mainCarName'],
	computed: {
		...mapGetters({
			getCarPrice_: 'others/getCarPrice',
		}),
		prices() {
			return {
				mainCar: this.getCarPrice_(this.mainCar.model_id) || {},
				comparedCar: this.getCarPrice_(this.comparedCar.model_id) || {},
			};
		},
	},
	i18n: require('../i18n').default,
};
