const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'CompareCar',
	mixins: [require('../../mixins/slonEventLogger')],
	components: {
		'breadcrumbs': require('../Common/Breadcrumbs/index.vue').default,
		'float-line': require('./FloatLine/index.vue').default,
		'page-titles': require('./PageTitles/index.vue').default,
		'compared-cars': require('./ComparedCars/index.vue').default,
		'common-info': require('./CommonInfo/index.vue').default,
		'cars-characteristics': require('./CarsCharacteristics/index.vue').default,
		'cars-reviews': require('./CarsReviews/index.vue').default,
		'new-cars-slider': require('./NewCarsSlider/index.vue').default,
		'bu-cars-slider': require('./BUCarsSlider/index.vue').default,
		'more-compare': require('./MoreCompare/index.vue').default,
	},
	init({store, route, target, context}) {
		if (target === 'node') {
			const { to: {params: { link = '' } = {}} = {}} = route;
			const { 'lang/prefix': prefix } = store.getters;
			return store.dispatch('compareCars/fetch', link.replace(/\.html/, ''))
				.then(({status = 200, url = '', mainCar = {}, comparedCar = {}} = {}) => {
					if (status >= 300 && url) {
						return context.response.redirect(url);
					} else if (!Object.keys(mainCar).length && !Object.keys(comparedCar).length) {
						return context.response.redirect(prefix + '/compare');
					} else {
						const params = {
							page: 1,
							items_per_page: 9,
							markaId: mainCar.markaId,
							modelId: mainCar.modelId,
						};
						return Promise.all([
							store.dispatch('compareCars/getCompareCouples', params),
							store.dispatch('others/carPrices', { marka_id: mainCar.markaId, model_id: mainCar.modelId }),
							store.dispatch('others/carPrices', { marka_id: comparedCar.markaId, model_id: comparedCar.modelId })
						]).catch(e => console.error(e));
					}
				}).catch(e => console.error(e));
		}
	},
	data() {
		return {
			isShowFloatBlock: false,
			link: this._result(this.$route, 'params.link', '').replace(/\.html/, ''),
			fullLink: this._result(this.$route, 'params.link', ''),
			breadcrumbsList: [
				{path: 'compare', title: this.$t('Порівняння авто')},
			],
			dataLoaded: false
		};
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{name: 'keywords', content: `${this.$t('Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -')} ${this.mainCarName} ${this.$t('або')} ${this.comparedCarName}`},
				{property: 'og:title', content: this.title},
				{property: 'og:description', content: this.description},
				{property: 'og:image', content: 'https://auto.ria.com/share_16.png'},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:url', content: `https://auto.ria.com/compare/${this.fullLink}`},
				{name: 'telegram:channel', content: '@auto_ria_news'},
				{name: 'twitter:card', content: 'summary'},
				{name: 'twitter:site', content: '@AUTO_RIA_com'},
				{name: 'twitter:creator', content: '@AUTO_RIA_com'},
				{name: 'twitter:image', content: 'https://auto.ria.com/share_16.png'},
				{name: 'twitter:domain', content: 'https://auto.ria.com/'},
				{name: 'twitter:description', content: this.description},
				{name: 'twitter:title', content: this.title},
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: `https://auto.ria.com/compare/${this.fullLink}`},
				{rel: 'alternate', hreflang: 'uk-UA', href: `https://auto.ria.com/uk/compare/${this.fullLink}`},
				{rel: 'alternate', hreflang: 'ru', href: `https://auto.ria.com/compare/${this.fullLink}`},
				{rel: 'alternate', hreflang: 'uk', href: `https://auto.ria.com/uk/compare/${this.fullLink}`},
				{rel: 'alternate', hreflang: 'x-default', href: `https://auto.ria.com/compare/${this.fullLink}`},
				{rel: 'canonical', href: `https://auto.ria.com${this.langPrefix}/compare/${this.fullLink}`},
				{rel: 'amphtml', href: `https://auto.ria.com${this.langPrefix}/compare/${this.link}.amp.html`},
			]
		};
	},
	computed: {
		...mapGetters({
			userData_: 'Common/userData',
			couple_: 'compareCars/couple',
		}),
		couple() {
			let data = this.couple_(this.link) || {};
			if (data.mainCar && data.comparedCar) {
				this.dataLoaded = true;
			}
			return data;
		},
		mainCar() {
			return this._result(this.couple, 'mainCar', {});
		},
		comparedCar() {
			return this._result(this.couple, 'comparedCar', {});
		},
		mainCarName() {
			return `${this._result(this.mainCar, 'markaName', '')} ${this._result(this.mainCar, 'modelName', '')}`;
		},
		comparedCarName() {
			return `${this._result(this.comparedCar, 'markaName', '')} ${this._result(this.comparedCar, 'modelName', '')}`;
		},
		description() {
			return `${this.$t('Порівнюйте автомобілі')} ${this.mainCarName} ${this.$t('та')} ${this.comparedCarName} ${this.$t('за технічними характеристиками, цінами і відгуками користувачів. Простий та зручний сервіс.')}`;
		},
		title() {
			return `AUTO.RIA – ${this.mainCarName} ${this.$t('або')} ${this.comparedCarName}? ${this.$t('Що краще?')}`;
		},
	},
	methods: {
		...mapActions({
			fetchCompareCars_: 'compareCars/fetch',
		}),
		showFloatBlock() {
			const IOW = require('../../helpers/IntersectionObserver_screen__01');
			IOW.add(this.$refs.showFloatBlock, ({ isIntersecting = false }) => {
				this.isShowFloatBlock = !isIntersecting;
			});
		},
		fetchCompareCars() {
			this.link = this._result(this.$route, 'params.link', '').replace(/\.html/, '');
			this.fetchCompareCars_(this.link).then(({status = 200, url = '', mainCar = {}, comparedCar = {}} = {}) => {
				if (status >= 300 && url) {
					this.$router.push(url);
				} else if (!Object.keys(mainCar).length && !Object.keys(comparedCar).length) {
					this.$router.push('/compare');
				}
			});
		},
		getValue(car = {}, treeName = 'tree', id = -1) {
			const values = this._result(car, `characteristic.${treeName}.val`, []);
			const treeIds = this._result(car, `characteristic.${treeName}.tree_id`, []);
			const index = treeIds.indexOf(id);
			return values[index] || '—';
		},
		sendSlonikEvent(params = {}) {
			this.sendEventToSlonik(params.click_action ? 855 : 336, Object.assign(
				{
					marka_id: [this.mainCar?.markaId, this.comparedCar?.markaId],
					model_id: [this.mainCar?.modelId, this.comparedCar?.modelId],
					year: [this.mainCar?.baseYear, this.comparedCar?.baseYear],
					price_from: [this.mainCar?.buautoPrice?.min, this.comparedCar?.buautoPrice?.min],
					price_to: [this.mainCar?.buautoPrice?.max, this.comparedCar?.buautoPrice?.max],
					transport_type_id: [this.mainCar?.categoryId, this.comparedCar?.categoryId],
				}, params));
		},
	},
	mounted() {
		this.showFloatBlock();
	},
	watch: {
		$route: 'fetchCompareCars',
		dataLoaded(newVal) {
			if (newVal) {
				this.sendSlonikEvent();
			}
		}
	},
	i18n: require('./i18n').default,
};
