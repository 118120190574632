const { mapGetters } = require('vuex');
import scrollToTop from '../../../helpers/cabinetScroll';

export default {
	name: 'MoreCompare',
	props: ['mainCar', 'mainCarName'],
	computed: {
		...mapGetters({
			moreCompares_: 'compareCars/moreCompares',
		}),
		moreCompares() {
			return this.moreCompares_ || [];
		},
		compareList() {
			return this.moreCompares.map(({models = []}) => {
				const [ mainCar = {}, comparedCar = {} ] = models;
				return {
					name: `${mainCar.markaName} ${mainCar.modelName} ${this.$t('з')} ${comparedCar.markaName} ${comparedCar.modelName}`,
					value: `${mainCar.markaEng}_${mainCar.modelEng}_vs_${comparedCar.markaEng}_${comparedCar.modelEng}.html`
				};
			});
		}
	},
	methods: {
		scrollToTop,
	},
	i18n: require('../i18n').default,
};
