export default {
	messages: {
		ru: {
			'Дивитись': 'Смотреть',
			'нових': 'новых | новое | новых | новых',
			'Нові': 'Новые',
			'Оголошення': 'Объявления',
			'і': 'и',
			'та': 'и',
			'або': 'или',
			'Порівняння': 'Сравнение',
			'з іншими авто': 'с другими авто',
			'Порівнюйте автомобілі': 'Сравнивайте автомобили',
			'за технічними характеристиками, цінами і відгуками користувачів. Простий та зручний сервіс.': 'по техническим характеристикам, ценам и отзывам пользователей. Простой и удобный сервис.',
			'Що краще?': 'Что лучше?',
			'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -': 'Помогаем ответить на вопрос какой автомобиль лучше, надежнее и круче -',
			'з': 'с',
			'Нет предложений': 'Нет предложений',
			'Вперед': 'Вперёд',
			'Порівняння авто': 'Сравнение авто',
		},
		uk: {
			'Дивитись': 'Дивитись',
			'нових': 'нових | нове | нових | нових',
			'Нові': 'Нові',
			'Оголошення': 'Оголошення',
			'і': 'і',
			'та': 'та',
			'або': 'або',
			'Порівняння': 'Порівняння',
			'з іншими авто': 'з іншими авто',
			'Порівнюйте автомобілі': 'Порівнюйте автомобілі',
			'за технічними характеристиками, цінами і відгуками користувачів. Простий та зручний сервіс.': 'за технічними характеристиками, цінами і відгуками користувачів. Простий та зручний сервіс.',
			'Що краще?': 'Що краще?',
			'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -': 'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -',
			'з': 'з',
			'Нет предложений': 'Немає пропозицій',
			'Вперед': 'Вперед',
			'Порівняння авто': 'Порівняння авто',
		},
	}
};
