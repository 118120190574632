import {mapGetters} from 'vuex';

module.exports = {
	computed: {
		...mapGetters({
			userData: 'Common/userData',
			langId: 'lang/id',
		})
	},
	methods: {
		sendEventToSlonik(eventId, payload = {}) {
			try {
				this._slonik(Object.assign({
					event_id: eventId,
					screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
					lang_id: this.langId,
					current_url: window.location.pathname,
					previous_url: document.referrer,
					owner_id: this.userData.userId,
					project_id: 1,
				}, payload));
			} catch (e) {
			}
		}
	},
};
