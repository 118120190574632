export default {
	messages: {
		ru: {
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лидер автомобильной интернет-торговли'
		},
		uk: {
			'AUTO.RIA — лидер автомобильной интернет-торговли': 'AUTO.RIA — лідер автомобільної інтернет-торгівлі'
		}
	}
};
