const { mapGetters } = require('vuex');

export default {
	name: 'CarsCharacteristics',
	props: ['link', 'getValue'],
	computed: {
		...mapGetters({
			couple_: 'compareCars/couple',
		}),
		couple() {
			return this.couple_(this.link) || {};
		},
		mainCar() {
			return this._result(this.couple, 'mainCar', {});
		},
		comparedCar() {
			return this._result(this.couple, 'comparedCar', {});
		},
	},
	methods: {
		onChange(index, event) {
			this.$emit('sendLogsEvent', {click_action: event.target.checked ? index : index + 100, action_block: 3});
		}
	}
};
