import { mapGetters } from 'vuex';

export default {
	name: 'Slide',
	props: ['id'],
	computed: {
		...mapGetters({
			advertisements: 'graphql/advertisements',
		}),
		advertisement() {
			return this.advertisements(this.id) || {};
		},
		img() {
			const { brand: { eng: brand = '' } = {}, model: { eng: model = '' } = {}, photos: { main } = {} } = this.advertisement;
			const { id } = main || {};
			return id ? `https://cdn.riastatic.com/photosnew/auto/photo/${brand}_${model}__${id}b.jpg` : 'https://img6.auto.ria.com/images/nophoto/no-photo-620x495.jpg';
		},
		title() {
			return `${this._result(this.advertisement, 'brand.name', '')} ${this._result(this.advertisement, 'model.name', '')} ${this._result(this.advertisement, 'year', '')}`;
		},
		price() {
			return this._result(this.advertisement, 'price.main.value', 0);
		}
	},
	methods: {
		logClick() {
			this.$emit('passChildLogsEvent', {proposal_id: Number(this.id), click_action: 3, action_block: 5});
		}
	}
};
