export default {
	messages: {
		ru: {
			'Порівнюємо': 'Сравниваем',
			'і': 'и',
			'або': 'или',
			'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -': 'Помогаем ответить на вопрос какой автомобиль лучше, надежнее и круче -',
		},
		uk: {
			'Порівнюємо': 'Порівнюємо',
			'і': 'і',
			'або': 'або',
			'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -': 'Допомагаємо відповісти на питання який автомобіль краще, надійніше і крутіше -',
		},
	}
};
