const { mapGetters } = require('vuex');

export default {
	name: 'ComparedCars',
	props: ['mainCar', 'comparedCar', 'comparedCarName', 'mainCarName'],
	computed: {
		...mapGetters({
			getCarPrice_: 'others/getCarPrice',
		}),
		prices() {
			return {
				mainCar: this.getCarPrice_(this.mainCar.model_id) || {},
				comparedCar: this.getCarPrice_(this.comparedCar.model_id) || {},
			};
		},
		cars() {
			return {
				mainCar: {
					count: this._result(this.prices, 'mainCar.total', 0),
					minPrice: Number(this._result(this.prices, 'mainCar.percentiles', {})['1.0']),
				},
				comparedCar: {
					count: this._result(this.prices, 'comparedCar.total', 0),
					minPrice: Number(this._result(this.prices, 'comparedCar.percentiles', {})['1.0']),
				}
			};
		},
	},
	methods: {
		getPhotoLink(car, format) {
			const photoInfo = this._result(this[car], 'photo', {});
			if (photoInfo.area && photoInfo.photoId) {
				return `https://cdn.riastatic.com/photosnewr/${photoInfo.area}/__${photoInfo.photoId}-341x224.${format}`;
			} else {
				// 'https://img6.auto.ria.com/images/nophoto/no-photo-295x195.jpg'
				return '';
			}
		}
	},
	i18n: require('../i18n').default,
};
