const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'BUCarsSlider',
	components: {
		'flickity': require('../../Common/Flickity/Flickity.vue').default,
		'slide': require('./components/Slide/index.vue').default,
	},
	props: ['link', 'comparedCarName', 'mainCarName'],
	data() {
		return {
			idsList: [],
			flickityOptions: {
				cellAlign: 'left',
				lazyLoad: true,
				pageDots: false,
				contain: true,
				groupCells: 1,
			},
			updateFlickity: 1,
			limit: 5,
			offset: 0,
			flickityPosition: 0,
			currentItem: 0
		};
	},
	computed: {
		...mapGetters({
			userData_: 'Common/userData',
			couple_: 'compareCars/couple',
		}),
		couple() {
			return this.couple_(this.link) || {};
		},
		mainCar() {
			return this._result(this.couple, 'mainCar', {});
		},
		comparedCar() {
			return this._result(this.couple, 'comparedCar', {});
		},
		userId() {
			return this._result(this.userData_, 'userId', 0);
		},
		userState() {
			return this._result(this.userData_, 'location.state.id', 0);
		},
		cars() {
			return Object.keys(this.mainCar).length && Object.keys(this.comparedCar).length ? {
				mainCar: this.mainCar,
				comparedCar: this.comparedCar,
			} : null;
		},
	},
	methods: {
		...mapActions({
			fetchSimilarAds_: 'others/similarAds',
			fetchAdvertisements_: 'graphql/advertisements',
		}),
		fetchSimilarAds() {
			const params = {
				limit: this.limit,
				userId: this.userId,
				state: this.userState,
				offset: this.offset,
			};
			const promises = [
				this.fetchSimilarAds_({advertisementId: 0, params: {...params, brandId: this._result(this.cars, 'mainCar.markaId'), modelId: this._result(this.cars, 'mainCar.modelId')}}),
				this.fetchSimilarAds_({advertisementId: 0, params: {...params, brandId: this._result(this.cars, 'comparedCar.markaId'), modelId: this._result(this.cars, 'comparedCar.modelId')}}),
			];
			Promise.all(promises).then(([mainCarIds = [], comparedCarIds = []]) => {
				if (Array.isArray(mainCarIds) && Array.isArray(comparedCarIds)) {
					const autos = [...mainCarIds, ...comparedCarIds].sort(() => Math.random() - 0.5);
					this.idsList = [...this.idsList, ...autos];
					this.offset += this.limit;
					this.fetchAdvertisements_(this.idsList).then(() => {
						this.updateFlickity += 1;
					});
				}
			});
		},
		clearList() {
			this.idsList = [];
			this.offset = 0;
		},
		flickityEvents(event, listener) {
			this.$refs.flickity.on(event, listener);
		},
		flickitySelectSlide(index) {
			this.$refs.flickity.selectCell(index, true, true);
		},
		flickityNext() {
			this.$refs.flickity.next();
		},
		flickityPrevious() {
			this.$refs.flickity.previous();
		},
		changeSlides() {
			this.flickitySelectSlide(this.flickityPosition);
			this.flickityEvents('change', (index) => {
				this.passLogsEvent({click_action: this.currentItem > index ? 2 : 1, action_block: 5});
				this.currentItem = index;
				if (this.isMobile) {
					this.currentItem *= 4;
				}
				if (this.currentItem >= this.idsList.length - this.limit) {
					this.fetchSimilarAds();
					this.flickityPosition = index;
				}
			});
		},
		updateFlickityOptions(isMobile) {
			this.flickityOptions.groupCells = isMobile ? 3 : 1;
			this.flickityOptions.prevNextButtons = !isMobile;
		},
		passLogsEvent(payload = {}) {
			this.$emit('sendLogsEvent', payload);
		}
	},
	watch: {
		cars(newValue) {
			if (newValue) {
				this.fetchSimilarAds();
			}
		},
		link: 'clearList',
		isMobile(nevValue) {
			this.updateFlickityOptions(nevValue);
			this.updateFlickity += 1;
			this.flickityPosition = 0;
		},
	},
	i18n: require('../i18n').default,
	mounted() {
		this.fetchSimilarAds();
		this.updateFlickityOptions(this.isMobile);
	}
};
