const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'CommonInfo',
	props: ['mainCar', 'comparedCar', 'getValue'],
	filters: {
		getClearPower(str = '') {
			return str.replace(/^(\d+)(\/)?(\d+)?/, '$1');
		},
	},
	computed: {
		...mapGetters({
			getCarPrice_: 'others/getCarPrice',
		}),
		dynamicPriceLink() {
			return {
				mainCar: `${this.langPrefix}/car/${this.mainCar.markaEng}/${this.mainCar.modelEng}/price/`,
				comparedCar: `${this.langPrefix}/car/${this.comparedCar.markaEng}/${this.comparedCar.modelEng}/price/`
			};
		},
		cars() {
			return Object.keys(this.mainCar).length && Object.keys(this.comparedCar).length ? {
				mainCar: this.mainCar,
				comparedCar: this.comparedCar,
			} : null;
		},
		prices() {
			return {
				mainCar: this.getCarPrice_(this.mainCar.model_id) || {},
				comparedCar: this.getCarPrice_(this.comparedCar.model_id) || {},
			};
		},
		averagePrices() {
			return {
				mainCar: this._result(this.prices.mainCar, 'arithmeticMean', 0) ? this.formatNumber(Math.floor(this._result(this.prices.mainCar, 'arithmeticMean', 0))) : '—',
				comparedCar: this._result(this.prices.comparedCar, 'arithmeticMean', 0) ? this.formatNumber(Math.floor(this._result(this.prices.comparedCar, 'arithmeticMean', 0))) : '—',
			};
		},
	},
	methods: {
		...mapActions({
			carPrices_: 'others/carPrices',
		}),
		showTip(currentCarValue, anotherCarValue, type) {
			const getNumber = (val = 0) => Number(val) ? Number(val) : parseInt(val);
			currentCarValue = getNumber(currentCarValue);
			anotherCarValue = getNumber(anotherCarValue);
			switch (type) {
				case 'minus':
					// eslint-disable-next-line max-len
					return currentCarValue < anotherCarValue ? (anotherCarValue - currentCarValue).toFixed(1) : false;
				case 'plus':
					// eslint-disable-next-line max-len
					return currentCarValue > anotherCarValue ? (currentCarValue - anotherCarValue).toFixed(1) : false;
				case 'percent':
					// eslint-disable-next-line max-len
					return currentCarValue < anotherCarValue ? (((anotherCarValue - currentCarValue) / anotherCarValue) * 100).toFixed(1) : false;
			}
		},
		carPrices() {
			const promises = [
				this.carPrices_({
					marka_id: this._result(this.cars, 'mainCar.markaId'),
					model_id: this._result(this.cars, 'mainCar.modelId'),
				}),
				this.carPrices_({
					marka_id: this._result(this.cars, 'comparedCar.markaId'),
					model_id: this._result(this.cars, 'comparedCar.modelId'),
				})
			];
			Promise.all(promises);
		},
	},
	watch: {
		cars(newValue) {
			if (newValue) {
				this.carPrices();
			}
		},
	},
	i18n: require('./i18n').default,
};
