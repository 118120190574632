const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'NewCarsSlider',
	props: ['link', 'comparedCarName', 'mainCarName'],
	data() {
		return {
			list: [],
			flickityOptions: {
				cellAlign: 'left',
				lazyLoad: true,
				pageDots: false,
				contain: true,
			},
			offset: 0,
			limit: 5,
			flickityPosition: 0,
			currentItem: 0
		};
	},
	components: {
		'flickity': require('../../Common/Flickity/Flickity.vue').default,
	},
	computed: {
		...mapGetters({
			couple_: 'compareCars/couple',
		}),
		couple() {
			return this.couple_(this.link) || {};
		},
		mainCar() {
			return this._result(this.couple, 'mainCar', {});
		},
		comparedCar() {
			return this._result(this.couple, 'comparedCar', {});
		},
		cars() {
			return Object.keys(this.mainCar).length && Object.keys(this.comparedCar).length ? {
				mainCar: this.mainCar,
				comparedCar: this.comparedCar,
			} : null;
		},
	},
	methods: {
		...mapActions({
			fetchInformerAutos_: 'others/newAutoInformer',
		}),
		fetchInformerAutos() {
			// sort -  сортування по витраті палива (якщо 0 то це електричка)
			const params = {
				limit: this.limit,
				offset: this.offset,
			};
			const promises = [
				this.fetchInformerAutos_({sort: 7, params: {...params, marka_id: this._result(this.cars, 'mainCar.markaId'), model_id: this._result(this.cars, 'mainCar.modelId')}}),
				this.fetchInformerAutos_({sort: 7, params: {...params, marka_id: this._result(this.cars, 'comparedCar.markaId'), model_id: this._result(this.cars, 'comparedCar.modelId')}}),
			];
			Promise.all(promises).then(([mainCarInformers = {}, comparedCarInformers = {}] = []) => {
				if (Array.isArray(mainCarInformers.autos) && Array.isArray(comparedCarInformers.autos)) {
					const autos = [...mainCarInformers.autos, ...comparedCarInformers.autos].sort(() => Math.random() - 0.5);
					this.list = [...this.list, ...autos];
					this.offset += this.limit;
				}
			});
		},
		clearList() {
			this.list = [];
			this.offset = 0;
		},
		flickityEvents(event, listener) {
			this.$refs.flickity.on(event, listener);
		},
		flickitySelectSlide(index) {
			this.$refs.flickity.selectCell(index, true, true);
		},
		changeSlides() {
			this.flickitySelectSlide(this.flickityPosition);
			this.flickityEvents('change', (index) => {
				this.logClick(this.currentItem > index ? 2 : 1);
				this.currentItem = index;
				if (index >= this.list.length - this.limit) {
					this.fetchInformerAutos();
					this.flickityPosition = index;
				}
			});
		},
		logClick(action, proposalId) {
			this.$emit('sendLogsEvent', {click_action: action, proposal_id: proposalId, action_block: 6, form_type: 2});
		}
	},
	watch: {
		cars(newValue) {
			if (newValue) {
				this.fetchInformerAutos();
			}
		},
		link: 'clearList'
	},
	i18n: require('../i18n').default,
	mounted() {
		this.fetchInformerAutos();
	}
};
