export default {
	messages: {
		ru: {
			'відгуків': 'отзывов | отзыв | отзыва | отзывов',
			'добре': 'хорошо',
			'відмінно': 'отлично',
			'погано': 'плохо',
			'Общий рейтинг': 'Общий рейтинг',
			'место': 'место',
			'Плюсы': 'Плюсы',
			'Минусы': 'Минусы',
			'Читать': 'Читать',
			'По данной модели пока нет отзывов': 'По данной модели пока нет отзывов',
			'Оставьте отзыв': 'Оставьте отзыв',
			'Читать весь отзыв': 'Читать весь отзыв',
			'Нету плюсов': 'Нету плюсов',
			'Нету минусов': 'Нету минусов',
			'Нет рейтинга': 'Нет рейтинга',
		},
		uk: {
			'відгуків': 'відгуків | відгук | відгука | відгуків',
			'добре': 'добре',
			'відмінно': 'відмінно',
			'погано': 'погано',
			'Общий рейтинг': 'Загальний рейтинг',
			'место': 'місце',
			'Плюсы': 'Плюси',
			'Минусы': 'Мінуси',
			'Читать': 'Читати',
			'По данной модели пока нет отзывов': 'Поки що немає відгуків про цю модель',
			'Оставьте отзыв': 'Залишіть відгук',
			'Читать весь отзыв': 'Читати весь відгук',
			'Нету плюсов': 'Немає плюсів',
			'Нету минусов': 'Немає мінусів',
			'Нет рейтинга': 'Немає рейтингу',
		},
	}
};
