export default {
	name: 'Breadcrumbs',
	props: {
		list: {
			type: Array,
			default() {
				return [];
			}
		},
		// Якщо потрібто показати поточну сторінку як текст
		currentPage: String,
	},
	computed: {
		pathList() {
			let currentPath = '';
			return this.list.reduce((acc, item) => {
				currentPath += item.path + '/';
				acc.push(currentPath);
				return acc;
			}, []);
		}
	},
	i18n: require('./i18n').default,
};
